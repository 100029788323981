import { Link } from 'gatsby';
import React from 'react';
import LegalLayout from '../components/layout/LegalLayout';

const name = 'Slab, Inc.';
const updatedAt = 'Mar 19, 2020';
const privacy = <Link to="/privacy/">Privacy Policy</Link>;
const pricing = <Link to="/pricing/">Pricing Terms</Link>;
const support = <a href="mailto:support@slab.com">support@slab.com</a>;
const mail = (
  <>
    340 S Lemon Ave #8017
    <br />
    Walnut, CA 91789
  </>
);

const Terms: React.SFC = () => (
  <LegalLayout title="Terms of Use" noindex>
    <section>
      <h1>Terms of Use</h1>
      <p>Last updated: {updatedAt}</p>
    </section>
    <section>
      <h2>Introduction</h2>
      <p>
        Welcome to Slab! Your use of Slab&apos;s services, including the
        services Slab makes available through this website and any other
        software or services offered by Slab in connection therewith (the
        &quot;Services&quot;) is governed by these terms of service (the
        &quot;Terms&quot;), so please carefully read them before using the
        Services. For the purposes of these Terms, &quot;we,&quot;
        &quot;our,&quot; &quot;us,&quot; and &quot;Slab&quot; refer to {name},
        the providers and operators of the Services.
      </p>
      <p>
        In order to use the Services, you must first agree to these Terms. If
        you are registering for or using the Services on behalf of an
        organization, you are agreeing to these Terms for that organization and
        promising that you have the authority to bind that organization to these
        Terms. In that case, &quot;you&quot; and &quot;your&quot; will also
        refer to that organization, wherever possible.
      </p>
      <p>
        You must be over 13 years of age to use the Services, and children under
        the age of 13 cannot use or register for the Services. If you are over
        13 years of age but are not yet of legal age to form a binding contract
        (in many jurisdictions, this age is 18), then you must get your parent
        or guardian to read these Terms and agree to them for you before you use
        the Services. If you are a parent or guardian and you provide your
        consent to your child&apos;s registration with the Services, you agree
        to be bound by these Terms with respect of your child&apos;s use of the
        Services.
      </p>
      <p>
        You agree your purchases and/or use of the Services are not contingent
        on the delivery of any future functionality or features or dependent on
        any oral or written public comments made by Slab or any of its
        affiliates regarding future functionality or features.
      </p>
      <p>
        BY USING, DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING THE SERVICES
        OR ANY MATERIALS INCLUDED IN OR WITH THE SERVICES, YOU HEREBY AGREE TO
        BE BOUND BY THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS, THEN YOU MAY
        NOT USE, DOWNLOAD, INSTALL, OR OTHERWISE ACCESS THE SERVICES.
      </p>
    </section>
    <section>
      <h2>Your Account</h2>
      <p>
        In the course of registering for or using the Services, you may be
        required to provide Slab with certain information, including your name,
        organization name, email, and password (&quot;Credentials&quot;). Slab
        handles such information with the utmost attention, care and security.
        Nonetheless, you, not Slab, shall be responsible for maintaining and
        protecting your Credentials in connection with the Services. If your
        contact information or other information relating to your account
        changes, you must notify Slab promptly and keep such information
        current. You are solely responsible for any activity using your
        Credentials, whether or not you authorized that activity. You should
        immediately notify Slab of any unauthorized use of your Credentials or
        if your email or password has been hacked or stolen. If you discover
        that someone is using your Credentials without your consent, or you
        discover any other breach of security, you agree to notify Slab
        immediately.
      </p>
      <p>
        If an organization invited or otherwise provided you with your account,
        that organization may have rights to your account and may: manage your
        account, reset your password, or suspend or cancel your account; view
        your account&apos;s usage and profile data, including how and when your
        account is used; and read or store Content in your account. If you are
        an individual user of the Services, and the domain of the primary email
        address associated with your account is owned by an organization and was
        assigned to you as an employee, contractor or member of such
        organization, and that organization wishes to establishes a commercial
        relationship with us and add your account to such relationship, then, if
        you do not change the email address associated with your account, your
        account may become subject to the commercial relationship between Slab
        and such organization and controlled by such organization.
      </p>
    </section>
    <section>
      <h2>Content</h2>
      <p>
        A variety of information, reviews, recommendations, messages, comments,
        posts, text, graphics, software, photographs, videos, data, and other
        materials (&quot;Content&quot;) may be made available through the
        Services by Slab or its suppliers (&quot;Slab-Supplied Content&quot;).
        While Slab strives to keep the Content that it provides through the
        Services accurate, complete, and up-to-date, Slab cannot guarantee, and
        is not responsible for the accuracy, completeness, or timeliness of any
        Slab-Supplied Content.
      </p>
      <p>
        You acknowledge that you will be able to create, transmit, publish or
        display information (such as data files, written text, computer
        software, music, audio files or other sounds, photographs, videos or
        other images) through use of the Services. All such information is
        referred to below as &quot;User Content.&quot;
      </p>
      <p>
        You agree that you are solely responsible for (and that Slab has no
        responsibility to you or to any third party for) any User Content, and
        for the consequences of your actions (including any loss or damage which
        Slab may suffer) in connection with such User Content. If you are
        registering for these Services on behalf of an organization, you also
        agree that you are also responsible for the actions of associated Users
        and for any User Content that such associated Users might upload,
        record, publish, post, link to, or otherwise transmit or distribute
        through use of the Services. Furthermore, you acknowledge that Slab does
        not control or actively monitor Content uploaded by users and, as such,
        does not guarantee the accuracy, integrity or quality of such Content.
        You acknowledge that by using the Services, you may be exposed to
        materials that are offensive, indecent or objectionable. Under no
        circumstances will Slab be liable in any way for any such Content. You
        will: (a) be solely responsible for the nature, quality and accuracy of
        your User Content; (b) ensure that your User Content (including the
        storage or transmission thereof) complies with these Terms and any and
        all applicable laws, and regulations; (c) promptly handle and resolve
        any notices and claims relating to your User Content, including any
        notices sent to you by any person claiming that any User Content
        violates any person&apos;s rights, such as take-down notices pursuant to
        the Digital Millennium Copyright Act and any other notices; and (d)
        maintain appropriate security, protection and backup copies of your User
        Content, which may include, your use of additional encryption technology
        to protect the Content from unauthorized access.
      </p>
      <p>
        Slab may refuse to store, provide, or otherwise maintain your User
        Content for any or no reason. Slab may remove your User Content from the
        Services at any time if you violate these Terms or if the Services are
        canceled or suspended. If User Content is stored using the Services with
        an expiration date, Slab may also delete the User Content as of that
        date. User Content that is deleted may be irretrievable. You agree that
        Slab will have no liability of any kind as a result of the deletion of,
        correction of, destruction of, damage to, loss of or failure to store or
        encrypt any User Content.
      </p>
      <p>
        Slab reserves the right (but shall have no obligation) to remove User
        Content from the Services, in its discretion. You agree to immediately
        take down any Content that violates these Terms, including pursuant to a
        takedown request from Slab. In the event that you elect not to comply
        with a request from Slab to take down certain Content, Slab reserves the
        right to directly take down such Content.
      </p>
      <p>
        By submitting, posting or otherwise uploading User Content on or through
        the Services you give Slab a worldwide, royalty-free, and non-exclusive
        license to reproduce, adapt, modify, translate, publish, publicly
        perform, publicly display and distribute such User Content for the
        purpose of enabling Slab to provide you with the Services, and for the
        limited purposes stated in our Privacy Policy.
      </p>
    </section>
    <section>
      <h2>Proprietary Rights</h2>
      <p>
        You acknowledge and agree that Slab (or Slab&apos;s licensors) own all
        legal right, title and interest in and to the Services and Slab-Supplied
        Content and that the Services and Slab-Supplied Content are protected by
        copyrights, trademarks, patents, or other proprietary rights and laws
        (whether those rights happen to be registered or not, and wherever in
        the world those rights may exist).
      </p>
      <p>
        Except as provided in Section 3, Slab acknowledges and agrees that it
        obtains no right, title or interest from you (or your licensors) under
        these Terms in or to any Content that you create, submit, post,
        transmit, share or display on, or through, the Services, including any
        intellectual property rights which subsist in that Content (whether
        those rights happen to be registered or not, and wherever in the world
        those rights may exist). Unless you have agreed otherwise in writing
        with Slab, you agree that you are responsible for protecting and
        enforcing those rights and that Slab has no obligation to do so on your
        behalf.
      </p>
      <p>
        You hereby agree that Slab may use your organization name and logo on
        its website and in its promotional materials to identify you as a Slab
        customer.
      </p>
    </section>
    <section>
      <h2>License from Slab and Restrictions on Use</h2>
      <p>
        Slab gives you a personal, worldwide, royalty-free, non-assignable and
        non-exclusive license to use the software provided to you as part of the
        Services. This license is solely to allow you to access the Services for
        your (or your organization&apos;s) non-commercial or internal business
        purposes, in the manner permitted by these Terms.
      </p>
      <p>
        You may not (and you may not permit anyone else to: (a) copy, modify,
        create a derivative work of, reverse engineer, decompile or otherwise
        attempt to extract the source code of the Services or any part thereof,
        unless this is expressly permitted or required by law, or unless you
        have been specifically told that you may do so by Slab, in writing
        (e.g., through an open source software license); or (b) attempt to
        disable or circumvent any security mechanisms used by the Services or
        any applications running on the Services.
      </p>
      <p>
        You may not engage in any activity that interferes with or disrupts the
        Services (or the servers and networks which are connected to the
        Services).
      </p>
      <p>
        You may not access the Services in a manner intended to avoid incurring
        fees or exceeding usage limits or quotas.
      </p>
      <p>
        You may not access the Services for the purpose of bringing an
        intellectual property infringement claim against Slab or for the purpose
        of creating a product or service competitive with the Services. You may
        not use any robot, spider, site search/retrieval application or other
        manual or automatic program or device to retrieve, index,
        &quot;scrape,&quot; &quot;data mine&quot; or in any way gather Content
        from the Services.
      </p>
      <p>
        You agree that you will not upload, record, publish, post, link to,
        transmit or distribute User Content, or otherwise utilize the Services
        in a manner that: (i) advocates, promotes, incites, instructs, informs,
        assists or otherwise encourages violence or any illegal activities; (ii)
        infringes or violates the copyright, patent, trademark, service mark,
        trade name, trade secret, or other intellectual property rights of any
        third party or Slab, or any rights of publicity or privacy of any party;
        (iii) attempts to mislead others about your identity or the origin of a
        message or other communication, or impersonates or otherwise
        misrepresents your affiliation with any other person or entity, or is
        otherwise materially false, misleading, or inaccurate; (iv) promotes,
        solicits or comprises inappropriate, harassing, abusive, profane,
        hateful, defamatory, libelous, threatening, obscene, indecent, vulgar,
        pornographic or otherwise objectionable or unlawful content or activity;
        (v) is harmful to minors; (vi) utilizes or contains any viruses, Trojan
        horses, worms, time bombs, or any other similar software, data, or
        programs that may damage, detrimentally interfere with, surreptitiously
        intercept, or expropriate any system, data, personal information, or
        property of another; or (vii) violates any law, statute, ordinance, or
        regulation (including without limitation the laws and regulations
        governing export control, unfair competition, anti-discrimination, or
        false advertising).
      </p>
      <p>
        You may not use the Services if you are a person barred from receiving
        the Services under the laws of the United States or other countries,
        including the country in which you are resident or from which you use
        the Services. You affirm that you are over the age of 13, as the
        Services are not intended for children under 13.
      </p>
    </section>
    <section>
      <h2>Pricing Terms</h2>
      <p>
        Subject to the Terms, the Services are provided to you without charge up
        to certain usage limits, and usage in excess of these limits may require
        purchase of additional resources and the payment of fees. Please see
        Slab&apos;s {pricing} for details regarding pricing for the Services.
      </p>
    </section>
    <section>
      <h2>Privacy Policies</h2>
      <p>
        These Services are provided in accordance with our Privacy Policy, which
        can be found at {privacy}. You agree to the use of your User Content and
        personal information in accordance with these Terms and Slab&apos;s
        Privacy Policy.
      </p>
    </section>
    <section>
      <h2>Modification and Termination of Services</h2>
      <p>
        Slab is constantly innovating in order to provide the best possible
        experience for its users. You acknowledge and agree that the form and
        nature of the Services which Slab provides may change from time to time
        without prior notice to you, subject to the terms in its Privacy Policy.
        Changes to the form and nature of the Services will be effective with
        respect to all versions of the Services; examples of changes to the form
        and nature of the Services include without limitation changes to fee and
        payment policies, security patches, added functionality, automatic
        updates, and other enhancements. Any new features that may be added to
        the website or the Services from time to time will be subject to these
        Terms, unless stated otherwise.
      </p>
      <p>
        You may terminate these Terms at any time by canceling your account on
        the Services. You will not receive any refunds if you cancel your
        account.
      </p>
      <p>
        You agree that Slab, in its sole discretion and for any or no reason,
        may terminate your account or any part thereof. You agree that any
        termination of your access to the Services may be without prior notice,
        and you agree that Slab will not be liable to you or any third party for
        such termination.
      </p>
      <p>
        You are solely responsible for exporting your Content from the Services
        prior to termination of your account for any reason, provided that if we
        terminate your account, we will endeavor to provide you a reasonable
        opportunity to retrieve your Content.
      </p>
      <p>
        Upon any termination of the Services or your account these Terms will
        also terminate, but all provisions of these Terms which, by their
        nature, should survive termination, shall survive termination,
        including, without limitation, ownership provisions, warranty
        disclaimers, and limitations of liability.
      </p>
    </section>
    <section>
      <h2>Changes to the Terms</h2>
      <p>
        These Terms may be amended or updated from time to time without notice
        and may have changed since your last visit to the website or use of the
        Services. It is your responsibility to review these Terms for any
        changes. By continuing to access or use the Services after revisions
        become effective, you agree to be bound by the revised Terms. If you do
        not agree to the new Terms, please stop using the Services. Please visit
        this page regularly to review these Terms for any changes.
      </p>
    </section>
    <section>
      <h2>DISCLAIMER OF WARRANTY</h2>
      <p>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES ARE AT
        YOUR SOLE RISK AND THAT THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND
        &quot;AS AVAILABLE.&quot;
      </p>
      <p>
        SLAB, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS MAKE NO EXPRESS
        WARRANTIES AND DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE SERVICES,
        INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT. WITHOUT LIMITING THE GENERALITY
        OF THE FOREGOING, SLAB, ITS SUBSIDIARIES AND AFFILIATES, AND ITS
        LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (A) YOUR USE OF THE
        SERVICES WILL MEET YOUR REQUIREMENTS, (B) YOUR USE OF THE SERVICES WILL
        BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, AND (C) USAGE DATA
        PROVIDED THROUGH THE SERVICES WILL BE ACCURATE.
      </p>
      <p>
        NOTHING IN THESE TERMS, INCLUDING SECTIONS 10 AND 11, SHALL EXCLUDE OR
        LIMIT SLAB&apos;S WARRANTY OR LIABILITY FOR LOSSES WHICH MAY NOT BE
        LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW.
      </p>
    </section>
    <section>
      <h2>LIMITATION OF LIABILITY</h2>
      <p>
        SUBJECT TO SECTION 10 ABOVE, YOU EXPRESSLY UNDERSTAND AND AGREE THAT
        SLAB, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS SHALL NOT BE
        LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL OR
        EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER CAUSED AND UNDER
        ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE LIMITED TO, ANY
        LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF
        GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA SUFFERED, COST OF
        PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS.
        THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL
        PURPOSE OF ANY LIMITED REMEDY.
      </p>
      <p>
        THE LIMITATIONS ON SLAB&apos;S LIABILITY TO YOU IN THIS SECTION SHALL
        APPLY WHETHER OR NOT SLAB HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE
        OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING.
      </p>
      <p>
        SOME STATES AND JURISDICTIONS MAY NOT ALLOW THE LIMITATION OR EXCLUSION
        OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
        LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL
        SLAB&apos;S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES
        OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
        OTHERWISE) EXCEED THE AMOUNT THAT YOU HAVE ACTUALLY PAID FOR THE
        SERVICES IN THE PAST TWELVE MONTHS, OR ONE HUNDRED DOLLARS ($100.00),
        WHICHEVER IS GREATER.
      </p>
    </section>
    <section>
      <h2>Indemnification</h2>
      <p>
        You agree to hold harmless and indemnify Slab, and its subsidiaries,
        affiliates, officers, agents, employees, advertisers, licensors,
        suppliers or partners (collectively &quot;Slab and Partners&quot;) from
        and against any third party claim arising from or in any way related to
        (a) your breach of the Terms, (b) your use of the Services, (c) your
        violation of applicable laws, rules or regulations in connection with
        the Services, or (d) your User Content, including any liability or
        expense arising from all claims, losses, damages (actual and
        consequential), suits, judgments, litigation costs and attorneys&apos;
        fees, of every kind and nature.
      </p>
    </section>
    <section>
      <h2>Copyright Policy</h2>
      <p>
        We respect the intellectual property rights of others and expect our
        users to do the same. In accordance with the Digital Millennium
        Copyright Act, Title 17, United States Code, Section 512(c)(2) (the
        &quot;DMCA&quot;), we will respond expeditiously to claims of copyright
        infringement committed using the Services if such claims are reported to
        our Designated Copyright Agent identified in the sample notice below.
      </p>
      <p>DMCA Notice of Alleged Infringement (&quot;Notice&quot;)</p>
      <ul>
        <li>
          Identify the copyrighted work that you claim has been infringed, or if
          multiple copyrighted works are covered by this Notice, you may provide
          a representative list of the copyrighted works that you claim have
          been infringed.
        </li>
        <li>
          Identify the material or link you claim is infringing (or the subject
          of infringing activity) and to which access is to be disabled,
          including at a minimum, if applicable, the URL of the link or the
          exact location where such material may be found.
        </li>
        <li>
          Provide your company affiliation (if applicable), mailing address,
          telephone number, and, if available, email address.
        </li>
        <li>
          Include both of the following statements in the body of the Notice:
          <ul>
            <li>
              &quot;I hereby state that I have a good faith belief that the
              disputed use of the copyrighted material is not authorized by the
              copyright owner, its agent, or the law (e.g., as a fair
              use).&quot;
            </li>
            <li>
              &quot;I hereby state that the information in this Notice is
              accurate and, under penalty of perjury, that I am the owner, or
              authorized to act on behalf of, the owner, of the copyright or of
              an exclusive right under the copyright that is allegedly
              infringed.&quot;
            </li>
          </ul>
        </li>
        <li>
          Provide your full legal name and your electronic or physical
          signature. Deliver this Notice, with all items completed, to our
          Designated Copyright Agent:
          <p className="address">
            Copyright Agent,
            <br />
            {name}
            <br />
            {mail}
            <br />
            {support}
          </p>
        </li>
      </ul>
    </section>
    <section>
      <h2>Third-Party Content</h2>
      <p>
        The Services may include references or hyperlinks to other web sites or
        content or resources or email content. Slab has no control over any web
        sites or resources which are provided by companies or persons other than
        Slab.
      </p>
      <p>
        You acknowledge and agree that Slab is not responsible for the
        availability of any such external sites or resources, and does not
        endorse any advertising, products or other materials on or available
        from such web sites or resources.
      </p>
      <p>
        You acknowledge and agree that Slab is not liable for any loss or damage
        which may be incurred by you or other users as a result of the
        availability of those external sites or resources, or as a result of any
        reliance placed by you on the completeness, accuracy or existence of any
        advertising, products or other materials on, or available from, such web
        sites or resources.
      </p>
    </section>
    <section>
      <h2>Third Party Software</h2>
      <p>
        The Services may incorporate certain third party software (&quot;Third
        Party Software&quot;), which is licensed subject to the terms and
        conditions of the third party licensing such Third Party Software.
        Nothing in these Terms limits your rights under, or grants you rights
        that supersede, the terms and conditions of any applicable license for
        such Third Party Software.
      </p>
    </section>
    <section>
      <h2>Feedback</h2>
      <p>
        You may choose to or we may invite you to submit comments or ideas about
        the Services, including without limitation about how to improve the
        Services or our products. By submitting any feedback, you agree that
        your disclosure is gratuitous, unsolicited and without restriction and
        will not place Slab under any fiduciary or other obligation, and that we
        are free to use such feedback without any additional compensation to
        you, and/or to disclose such feedback on a non-confidential basis or
        otherwise to anyone.
      </p>
    </section>
    <section>
      <h2>Miscellaneous</h2>
      <p>
        These Terms, together with our Privacy Policy and Pricing Terms (if
        available), constitutes the entire agreement between the parties
        relating to the Services and all related activities. These Terms shall
        not be modified except in writing signed by both parties or by a new
        posting of these Terms issued by us. If any part of these Terms is held
        to be unlawful, void, or unenforceable, that part shall be deemed
        severed and shall not affect the validity and enforceability of the
        remaining provisions. The failure of Slab to exercise or enforce any
        right or provision under these Terms shall not constitute a waiver of
        such right or provision. Any waiver of any right or provision by Slab
        must be in writing and shall only apply to the specific instance
        identified in such writing. You may not assign these Terms, or any
        rights or licenses granted hereunder, whether voluntarily, by operation
        of law, or otherwise without our prior written consent. These Terms and
        any action related thereto will be governed by the laws of the State of
        California without regard to its conflict of laws provisions. The
        exclusive jurisdiction and venue of any action with respect to the
        subject matter of these Terms will be the state and federal courts
        located in San Francisco, California, and each of the parties hereto
        waives any objection to jurisdiction and venue in such courts.
      </p>
    </section>
    <section>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about these Terms or if you wish to make any
        complaint or claim with respect to the Services, please contact us at:{' '}
        {support}
      </p>
      <p>
        When submitting a complaint, please provide a brief description of
        nature of your complaint and the specific services to which your
        complaint relates.
      </p>
    </section>
  </LegalLayout>
);

export default Terms;
